import { datadogRum } from '@datadog/browser-rum';
import serverVars from 'server-vars';
import { initDatadogRum, setDatadogRumUserId } from 'dibs-checkout/exports/datadog';
import { getAbTestV2 } from 'dibs-ab-tests/exports/clientAbTestV2';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { getTrafficType } from 'dibs-tracking';

export const pdpTypes = {
    marketplace: 'marketplace',
    unavailable: 'unavailable',
    condensed: 'condensed',
    private: 'private',
} as const;

export type PdpType = (typeof pdpTypes)[keyof typeof pdpTypes];

export const sbTypes = {
    search: PAGE_TYPE.SEARCH,
    buy: PAGE_TYPE.BUY,
    browse: PAGE_TYPE.BROWSE,
    similarSold: 'similar',
    visuallySimilar: 'similar',
} as const;
type SbType = (typeof sbTypes)[keyof typeof sbTypes];

type DeviceType = 'resp' | 'mobile';
const pageData = {
    [PAGE_TYPE.PDP]: { name: PAGE_TYPE.PDP, type: 'dibsPdpType', sampleRateKey: 'pdpSampleRate' },
    [PAGE_TYPE.BROWSE]: {
        name: PAGE_TYPE.BROWSE,
        type: 'dibsSbType',
        sampleRateKey: 'sbSampleRate',
    },
    [PAGE_TYPE.ORDER_MANAGER]: {
        name: PAGE_TYPE.ORDER_MANAGER,
        type: 'dibsBomtType',
        sampleRateKey: 'bomtSampleRate',
    },
} as const;

const abTests = serverVars.get('abTestsV2');
const DD_RUM = serverVars.get('DD_RUM');
const locale = serverVars.get('locale');

export const initSharedDatadogRum = ({
    device,
    page,
    pageType,
}:
    | { device: DeviceType; page: typeof PAGE_TYPE.PDP; pageType: PdpType }
    | { device: DeviceType; page: typeof PAGE_TYPE.BROWSE; pageType: SbType }
    | { device: DeviceType; page: typeof PAGE_TYPE.ORDER_MANAGER; pageType: 'bomt' }): void => {
    if (!DD_RUM) {
        return;
    }

    const { name, sampleRateKey, type } = pageData[page];
    const sampleRate = (DD_RUM[sampleRateKey] || 0) / 100;
    if (Math.random() >= sampleRate) {
        return;
    }

    initDatadogRum();
    setDatadogRumUserId();
    datadogRum.startView({
        name,
        service: DD_RUM.service,
        version: DD_RUM.version,
    });
    datadogRum.setGlobalContextProperty('dibsDevice', device);
    datadogRum.setGlobalContextProperty(type, pageType);
    datadogRum.setGlobalContextProperty('dibsLocale', locale);
    const trafficType = getTrafficType(document.cookie, new URL(window.location.href));
    if (trafficType) {
        datadogRum.setGlobalContextProperty('trafficType', trafficType);
    }
    try {
        for (const testName of Object.keys(abTests)) {
            const abTest = getAbTestV2(testName);
            if (abTest?.variant) {
                datadogRum.setGlobalContextProperty(testName, abTest.variant);
            }
        }
    } catch {
        // do nothing
    }
};
