import { GraphQLError } from 'graphql';
import {
    Observable,
    RequestParameters,
    Variables,
    SubscribeFunction,
    CacheConfig,
} from 'relay-runtime';
import { createClient, Sink } from 'graphql-ws';
import serverVars from 'server-vars';
import { getSocketListeners } from 'dibs-subscriptions-utils/exports/getSocketListeners';
import { SocketListeners } from 'dibs-subscriptions-utils/exports/types';

import { CreateFetcherArgs } from './client';
import { getClientHeaders } from './src/getClientHeaders';
import { sendMessage, getSchemaToken } from './src/chromeExtension';

type SetupSubscriptionArgs = {
    getQueries: CreateFetcherArgs['getQueries'];
    userType: CreateFetcherArgs['userType'];
    getGraphQLNetworkContext?: () => string | null;
};
type SetupSubscriptionsReturn = {
    fetchOrSubscribe: SubscribeFunction;
    socketListeners: SocketListeners;
};
type CloseEvent = {
    code: number;
};
const isCloseEvent = (errOrCloseEvent: unknown): errOrCloseEvent is CloseEvent => {
    return (errOrCloseEvent as CloseEvent).code !== undefined;
};

const NODE_GRAPHQL_SUBSCRIPTIONS_URL = serverVars.get('NODE_GRAPHQL_SUBSCRIPTIONS_URL');
const MAX_RECONNECTION_WAIT = 3 * 60 * 1000;
const RECONNECTION_ATTEMPTS = 200;
const ERROR_CODE_FORBIDDEN = 4403;
const WS_LAZY_CLOSE_TIMEOUT = 5000;

function randomizedExponentialBackoff(retryCount: number): Promise<void> {
    // if offline, wait until back online, then reconnect immediately
    if (!window.navigator.onLine) {
        return new Promise(resolve => {
            function onOnline(): void {
                window.removeEventListener('online', onOnline);
                resolve();
            }
            window.addEventListener('online', onOnline);
        });
    }
    let retryWait = 3000; // 3s in dev for quick reconnect after gql restarts
    if (process.env.NODE_ENV !== 'development') {
        // exponential backoff - once initial attempts fail isssue will likely take more than a few seconds to resolve
        retryWait = 1000 * Math.pow(2, retryCount); // 1000, 2000, 4000, 8000, ...
        // exponentail backoff can get very large - limit to MAX_RECONNECTION_WAIT
        retryWait = Math.min(retryWait, MAX_RECONNECTION_WAIT);
        // add random delay up to 3s to prevent reconnection flood
        const maxRandomDelay = Math.min(retryWait, 3000);
        retryWait += Math.floor(Math.random() * maxRandomDelay);
    }
    return new Promise(resolve => setTimeout(resolve, retryWait));
}

export function setupSubscriptions({
    getQueries = () => ({}),
    userType,
    getGraphQLNetworkContext = () => null,
}: SetupSubscriptionArgs): SetupSubscriptionsReturn {
    const subscriptionsClient = createClient({
        url: NODE_GRAPHQL_SUBSCRIPTIONS_URL,
        retryAttempts: RECONNECTION_ATTEMPTS,
        lazy: true, // establish ws on first subscribe, close on last unsubscribe
        lazyCloseTimeout: WS_LAZY_CLOSE_TIMEOUT,
        retryWait: randomizedExponentialBackoff,
        isFatalConnectionProblem(errOrCloseEvent) {
            if (isCloseEvent(errOrCloseEvent) && errOrCloseEvent.code === ERROR_CODE_FORBIDDEN) {
                return true; // fail immediatly - server does not allow ws connections
            }
            return false; // attempt reconnection
        },
    });

    // get schema token for graphql chrome extension
    // note: there may be a race condition in the unlikely event that a subscription is initiated immedatly after setupSubscriptions is called
    //  making setupSubscriptions async would slow page load
    let schemaToken: string | null = null;
    getSchemaToken().then(token => {
        schemaToken = token;
    });

    // can be used for both fetch & subscribe
    function fetchOrSubscribe(
        operation: RequestParameters,
        variables: Variables,
        cacheConfig?: CacheConfig
    ): ReturnType<SubscribeFunction> {
        return Observable.create(sink => {
            if (!operation.text && !operation.id) {
                return sink.error(new Error('Operation must contain text or id'));
            }
            const headers = getClientHeaders(
                getQueries,
                userType,
                getGraphQLNetworkContext,
                operation,
                cacheConfig
            );
            if (schemaToken) {
                headers['x-dibs-debug-graphql'] = schemaToken;
            }
            return subscriptionsClient.subscribe(
                {
                    operationName: operation.name,
                    query: operation.text || 'use persisted query',
                    variables,
                    extensions: {
                        queryId: operation.id, // persisted query
                        // send params on each new subscription (instead of using connectionParams) - allows updating token on auth change
                        connectionParams: {
                            originalUrl: window.location.href,
                            headers,
                        },
                    },
                },
                {
                    ...(sink as Sink),
                    error: err => {
                        if (err instanceof Error) {
                            return sink.error(err);
                        }
                        if (err instanceof CloseEvent) {
                            return sink.error(
                                new Error(
                                    `Socket closed with event ${err.code} ${err.reason || ''}`
                                )
                            );
                        }
                        return sink.error(
                            new Error(
                                (err as GraphQLError[]).map(({ message }) => message).join(', ')
                            )
                        );
                    },
                }
            );
        });
    }

    // manually send debug info to gql extension b/c extension cannot intercept ws data like xhr
    subscriptionsClient.on('message', message => {
        if (schemaToken && message.type === 'next') {
            sendMessage(message.payload);
        }
    });

    return {
        fetchOrSubscribe,
        socketListeners: getSocketListeners(subscriptionsClient),
    };
}
